import React, { useState, useEffect } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { rem, swap, position } from 'styled-tidy'
import debounce from 'lodash.debounce'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import GlobalStyle from 'lib/styles/global'
import { scrollToY } from 'lib/common/scroll'
import stopEvent from 'lib/common/stop-event'
import SiteWrapper from 'components/site-wrapper'
import Head from 'components/head'
import Header from 'components/header'
import Footer from 'components/footer'
import FlashMessages from 'components/flash-messages'
import ArrowCircle from 'components/icons/arrow-circle'

const { colors, speeds } = theme
const { vapor, yellow, pink } = colors

const Main = styled.main`
  background: ${vapor};
  position: relative;
  text-align: center;

  :before {
    ${position('absolute', -48, 0, 'auto')}
    color: ${pink};
    content: '\u261F';
    font-size: ${rem(24)};
    line-height: ${rem(32)};
    text-align: center;
    z-index: 2;
  }
`

const ScrollUp = styled.a`
  animation:
    ${swap('show', 'fadeIn', 'fadeOut')}
    ${speeds.gradual}ms forwards ease;
  pointer-events: ${swap('show', 'initial', 'none')};
  position: fixed;
  left: calc(100vw - ${rem(72)});
  top: calc(100vh - ${rem(96)});
  user-select: none;
  z-index: 9;

  path {
    transition: all ${speeds.quick}ms ease;
  }

  :hover,
  :focus {
    path {
      fill: ${yellow};
    }
  }

  ${media.medium`
    left: calc(100vw - ${rem(72)});
    top: calc(100vh - ${rem(72)});
  `}

  ${media.large`
    svg {
      height: ${rem(48)};
      width: ${rem(48)};
    }
  `}

  ${media.xlarge`
    left: calc(50% + ${rem(504)});
  `}
`

const Layout = ({ path, subheader, children }) => {
  const [showScrollUp, setShowScrollUp] = useState(false)

  const handleScrollUp = event => {
    stopEvent(event)
    scrollToY(0)
  }

  const handleWindowScroll = debounce(() => {
    const show = global.scrollY > window.innerHeight
    if (show !== showScrollUp) setShowScrollUp(show)
  }, 300)

  useEffect(() => {
    global.addEventListener('scroll', handleWindowScroll)
    return () => global.removeEventListener('scroll', handleWindowScroll)
  }, [handleWindowScroll])

  return (
    <ThemeProvider theme={theme}>
      <SiteWrapper>
        <GlobalStyle />
        <Head path={path} />
        <Header path={path} subheader={subheader} />
        <FlashMessages />
        <Main>{children}</Main>
        <ScrollUp show={showScrollUp} onClick={handleScrollUp}>
          <ArrowCircle size={64} />
        </ScrollUp>
        <Footer />
      </SiteWrapper>
    </ThemeProvider>
  )
}

export default Layout
