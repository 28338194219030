export default {
  colors: {
    // Neon
    blue: '#2ADDFD',
    green: '#7EFC34',
    orange: '#FC8238',
    pink: '#FA4FB6',
    purple: '#CA3EFB',
    yellow: '#FEF15E',

    // Grayscale
    white: '#FFFFFF',
    vapor: '#F0F0F0',
    pearl: '#E2E2E2',
    smoke: '#CFCFCF',
    silver: '#BBBBBB',
    gray: '#8C8C8C',
    iron: '#5A5A5A',
    ember: '#333333',
    tar: '#161616',
    black: '#000000',

    // Transparent
    shadow: 'rgba(0, 0, 0, 0.1)',
  },
  speeds: {
    fast: 100,
    quick: 250,
    gradual: 400,
    slow: 600,
  },
  fonts: {
    primary: 'Oswald, sans-serif',
    secondary: 'Montserrat, sans-serif',
  },
}
