import React, { useState } from 'react'
import { inject } from 'mobx-react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import siteConfig from 'config/site'

const Head = ({
  global: globalStore,
  cart: cartStore,
  path,
  description,
  lang,
  meta,
  title,
}) => {
  const [currentPath, setCurrentPath] = useState('')
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
            description
          }
        }
      }
    `,
  )
  const { siteMetadata } = data.site
  const metaTitle = title || siteMetadata.title
  const metaDescription = description || siteMetadata.description
  const titleTemplate = title
    ? `%s | ${siteMetadata.title}`
    : siteMetadata.title

  if (path !== currentPath) {
    setCurrentPath(path)
    globalStore.clearMessages()
    cartStore.setRecentlyAddedItem(null)
  }
  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={metaTitle}
      titleTemplate={titleTemplate}
      meta={[
        { name: 'description', content: metaDescription },
        { property: 'og:title', content: title },
        { property: 'og:description', content: metaDescription },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:creator', content: siteMetadata.author },
        { name: 'twitter:title', content: title },
        { name: 'twitter:description', content: metaDescription },
        {
          name: 'viewport',
          content:
            'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0',
        },
        ...(meta ? meta : []),
      ]}
    >
      <link href={siteConfig.fontSrc} rel="stylesheet" />
    </Helmet>
  )
}

export default inject('global', 'cart')(Head)
