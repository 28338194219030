import React from 'react'
import styled from 'styled-components'
import { rem, flex } from 'styled-tidy'
import theme from 'lib/styles/theme'
import X from 'components/icons/x'

const { colors, speeds } = theme
const { black, white, pearl, tar, ember } = colors

const Link = styled.a`
  ${flex('row', 'center', 'center')}
  align-self: stretch;
  background: ${tar};
  border-left: ${rem(1)} solid ${ember};
  cursor: pointer;
  line-height: ${rem(48)};
  transition: all ${speeds.quick}ms linear;
  user-select: none;
  width: ${rem(56)};

  svg {
    fill: ${pearl};
  }

  :hover {
    background: ${black};

    svg {
      animation: closeButtonHover ${speeds.quick}ms forwards ease-in-out;
      fill: ${white};
    }
  }
`

const CloseButton = ({ ...rest }) => (
  <Link {...rest}>
    <X size={12} />
  </Link>
)

export default CloseButton
