import React, { useState, useEffect } from 'react'
import throttle from 'lodash.throttle'
import styled from 'styled-components'
import { rem, flex, is, value } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'

const { colors, speeds } = theme
const { white, silver, yellow, blue, green, purple, orange, pink } = colors
const swatch = [yellow, blue, green, purple, orange, pink, white]
const defaultColor = silver
let LOGO_TEXT_TIMEOUT

const Wrapper = styled.div`
  ${flex('row', 'flex-start', 'flex-start')}
  letter-spacing: 0.1em;
  padding: 0 ${rem(16)};
  user-select: none;

  :hover span {
    color: ${white} !important;
  }

  ${media.small`
    letter-spacing: 0.2em;
  `}

  ${media.medium`
    letter-spacing: 0.3em;
  `}
`

const StyledLetter = styled.span`
  color: ${value('color')};
  font-weight: 600;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
  transition: all ${speeds.fast}ms linear;

  ${is('color', defaultColor)`
    transition-duration: ${speeds.slow}ms;
  `}
`

const LogoText = () => {
  const [colorize, setColorize] = useState(0)
  const letters = 'Evilneon'.split('')
  const randomColors = []

  const handleWindowScroll = throttle(() => {
    if (LOGO_TEXT_TIMEOUT) clearTimeout(LOGO_TEXT_TIMEOUT)
    LOGO_TEXT_TIMEOUT = setTimeout(() => setColorize(0), 1000)
    setColorize(global.scrollY)
  }, speeds.slow)

  useEffect(() => {
    global.addEventListener('scroll', handleWindowScroll)
    return () => {
      clearTimeout(LOGO_TEXT_TIMEOUT)
      global.removeEventListener('scroll', handleWindowScroll)
    }
  }, [handleWindowScroll])

  letters.forEach(() => {
    randomColors.push(swatch[Math.floor(Math.random() * swatch.length)])
  })
  return (
    <Wrapper aria-label="Evilneon">
      {letters.map((letter, key) => (
        <StyledLetter
          aria-hidden
          key={`logo-text-letter-${key}`}
          color={colorize ? randomColors[key] : defaultColor}
        >
          {letter}
        </StyledLetter>
      ))}
    </Wrapper>
  )
}

export default LogoText
