import { css } from 'styled-components'
import { rem } from 'styled-tidy'
import theme from 'lib/styles/theme'

const { yellow, blue, pink } = theme.colors

export default css`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes flashIn {
    0% {
      opacity: 0;
      transform: scale(1.2, 1);
    }
    50% {
      opacity: 0.5;
      transform: scale(0.95, 1);
    }
    100% {
      opacity: 1;
      transform: scale(1, 1);
    }
  }

  @keyframes badgeIn {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    50% {
      opacity: 1;
      transform: scale(1.2);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  @keyframes modalInMobile {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes modalInDesktop {
    0% {
      opacity: 0;
      transform: translate(-50%, calc(-50% - ${rem(200)}));
    }
    50% {
      transform: translate(-50%, calc(-50% + ${rem(48)}));
    }
    100% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  @keyframes modalTitleBarIn {
    0% {
      height: 0;
    }
    50% {
      height: ${rem(56)};
    }
    100% {
      height: ${rem(48)};
    }
  }

  @keyframes bounceLetter {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    33% {
      opacity: 0.5;
      transform: translateY(-20%);
    }
    66% {
      opacity: 1;
      transform: translateY(10%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes closeButtonHover {
    0% {
      transform: scale(1) rotate(0);
    }
    50% {
      transform: scale(1.5) rotate(100deg);
    }
    100% {
      transform: scale(1.25) rotate(90deg);
    }
  }

  @keyframes popUp {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: none;
    }
  }

  @keyframes popDown {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: none;
    }
  }

  @keyframes toastDown {
    0% {
      opacity: 0;
      transform: translateY(-100%) scaleY(0);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }

  @keyframes splashOpen {
    0% {
      height: 0;
    }
    50% {
      height: ${rem(208)};
    }
    100% {
      height: ${rem(192)};
    }
  }

  @keyframes progressBar {
    0% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(0);
    }
  }

  @keyframes colorCycle {
    0%,
    100% {
      fill: ${yellow};
    }
    33% {
      fill: ${blue};
    }
    67% {
      fill: ${pink};
    }
  }
`
