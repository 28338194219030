export default [
  'abbr',
  'b',
  'big',
  'br',
  'blockquote',
  'caption',
  'cite',
  'col',
  'colgroup',
  'dd',
  'dfn',
  'dl',
  'dt',
  'em',
  'figure',
  'figcaption',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'i',
  'img',
  'li',
  'mark',
  'ol',
  'p',
  'q',
  'small',
  'span',
  'strong',
  'sub',
  'sup',
  'table',
  'tbody',
  'td',
  'tfoot',
  'th',
  'thead',
  'tr',
  'time',
  'ul',
]
