import React from 'react'
import siteConfig from 'config/site'
import Instagram from 'components/icons/instagram'
import Facebook from 'components/icons/facebook'
import ExternalLink from 'components/external-link'
import styled from 'styled-components'
import { flex } from 'styled-tidy'
import theme from 'lib/styles/theme'

const { gray } = theme.colors

export const SocialLinksWrap = styled.span`
  ${flex('row', 'center', 'center')}
`

const SocialLinks = () => (
  <SocialLinksWrap>
    <ExternalLink href={siteConfig.social.instagram.url}>
      <Instagram size={20} fill={gray} />
      evil.neon
    </ExternalLink>
    <ExternalLink href={siteConfig.social.facebook.url}>
      <Facebook size={20} fill={gray} />
      Evilneon
    </ExternalLink>
  </SocialLinksWrap>
)

export default SocialLinks
