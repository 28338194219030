import React from 'react'
import styled from 'styled-components'
import { rem, flex } from 'styled-tidy'

export const Wrap = ({
  is = 'div',
  alignItems = 'center',
  flexDirection = 'column',
  justifyContent = 'center',
  small,
  children,
  ...rest
}) => {
  const Element = styled.div`
    ${flex(flexDirection, alignItems, justifyContent)}
    margin: 0 auto;
    max-width: 100%;
    padding: ${rem(16)};
    width: ${rem(small ? 576 : 1024)};
  `
  return (
    <Element as={is} {...rest}>
      {children}
    </Element>
  )
}

export default Wrap
