import React from 'react'
import styled from 'styled-components'
import { rem, is, isAny, isntAny } from 'styled-tidy'
import { Link } from 'gatsby'
import theme from 'lib/styles/theme'

const {
  fonts,
  speeds,
  colors: { black, white, vapor, smoke, silver, iron, gray, tar, pearl, green },
} = theme

const Button = ({ is: tag = 'button', external, children, ...rest }) => {
  const Element = styled(rest.to ? Link : tag)`
    align-items: center;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    font-family: ${fonts.primary};
    font-size: ${rem(12)};
    justify-content: center;
    line-height: ${rem(46)};
    letter-spacing: ${rem(2)};
    padding: ${rem(1)} ${rem(24)};
    position: relative;
    text-decoration: none;
    white-space: nowrap;
    text-align: center;
    text-transform: uppercase;
    transition:
      background ${speeds.gradual}ms ease-in-out,
      color ${speeds.gradual}ms ease-in-out;
    user-select: none;

    :active {
      padding: ${rem(2)} ${rem(24)} 0;
    }

    &[disabled],
    &[disabled]:hover {
      cursor: default;
    }

    ${is('size', 'slim')`
      line-height: ${rem(26)};
    `}

    ${isntAny('kind', ['primary', 'secondary'])`
      background: ${white};
      color: ${iron};

      :hover {
        background: ${vapor};
        color: ${black};
      }

      &[disabled],
      &[disabled]:hover {
        background: ${white};
        color: ${pearl};
      }
    `}

    ${isAny('kind', ['primary', 'secondary'])`
      :after {
        border-radius: ${rem(1)};
        content: '';
        height: ${rem(2)};
        left: 50%;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        transform: translateX(-50%);
        transition:
          all ${speeds.quick}ms ease-in-out,
          opacity ${speeds.fast}ms ease-in-out;
        width: 0;
      }

      :hover:after {
        opacity: 1;
      }

      &[disabled]:after {
        display: none;
      }
    `}

    ${is('kind', 'primary')`
      background: ${tar};
      color: ${pearl};

      :after {
        background: ${green};
        bottom: ${rem(2)};
      }

      :hover {
        background: ${black};
        color: ${white};

        :after {
          width: calc(100% - ${rem(4)});
        }
      }

      &[disabled],
      &[disabled]:hover {
        background: ${gray};
        color: ${pearl};
      }
    `}

    ${is('kind', 'secondary')`
      background: ${vapor};
      box-shadow: inset 0 0 0 ${rem(1)} ${smoke};
      color: ${iron};

      :after {
        background: ${gray};
        bottom: ${rem(3)};
      }

      :hover {
        box-shadow: inset 0 0 0 ${rem(1)} ${silver};
        color: ${black};

        :after {
          width: calc(100% - ${rem(6)});
        }
      }

      &[disabled],
      &[disabled]:hover {
        box-shadow: inset 0 0 0 ${rem(1)} ${pearl};
        color: ${smoke};
      }
    `}
  `

  return (
    <Element
      {...(external && { target: '_blank', rel: 'noopener noreferrer' })}
      {...rest}
    >
      {children}
    </Element>
  )
}

export default Button
