import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import styled from 'styled-components'
import { rem, flex, position, is, value, transparentize } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import CloseButton from 'components/close-button'

const MESSAGE_TIMEOUTS = {}

const { colors, speeds } = theme
const { white, pearl, ember, tar } = colors

const Wrapper = styled.div`
  ${position('fixed', 48, 0, 'auto')}
  ${is('menuOpen')`transform: translateX(${rem(-159)})`};
  transition: transform ${speeds.quick}ms ease-in-out;
  z-index: 5;
`

const MessageWrap = styled.div`
  ${flex('row', 'stretch', 'space-between')}
  animation: flashIn forwards ${speeds.quick}ms ease-in-out;
  background: ${tar};
  border: ${rem(1)} solid ${ember};
  color: ${pearl};
  margin: 0 auto;
  width: 100%;

  ${media.large`
    max-width: ${rem(992)};
  `}

  + div {
    border-top-width: 0 !important;
  }
`

const Message = styled.div`
  ${flex('row', 'center', 'flex-start')}
  color: ${pearl};
  font-size: ${rem(14)};
  flex: 1;
  padding: ${rem(16)};
  position: relative;

  span {
    position: relative;
    z-index: 2;
  }
`

const ProgressBar = styled.div`
  ${position('absolute', 0, 'auto', 0, 0)}
  animation: progressBar ${value('duration')}ms forwards linear;
  background: ${transparentize(white, 0.95)};
  border-radius: ${rem(2)};
  transform-origin: 0 0;
  width: 100%;
`

const FlashMesssages = ({ global: globalStore }) => {
  const { isMenuOpen, messages } = globalStore

  const removeTimeout = id => {
    if (id in MESSAGE_TIMEOUTS) return
    clearTimeout(MESSAGE_TIMEOUTS[id])
    delete MESSAGE_TIMEOUTS[id]
  }
  const removeMessage = id => {
    globalStore.removeMessage(id)
    removeTimeout(id)
  }

  useEffect(() => {
    messages.forEach(({ id, duration }) => {
      if (id in MESSAGE_TIMEOUTS) removeTimeout(id)
      MESSAGE_TIMEOUTS[id] = setTimeout(() => removeMessage(id), duration)
      return () =>
        Object.values(MESSAGE_TIMEOUTS).forEach(timeout =>
          clearTimeout(timeout),
        )
    })
  }, [messages, removeTimeout, removeMessage])

  if (!messages.length) return null

  return (
    <Wrapper menuOpen={isMenuOpen}>
      {messages.map(({ id, message, duration }) => (
        <MessageWrap key={`message-${id}`}>
          <Message>
            <span>{message}</span>
            <ProgressBar duration={duration} />
          </Message>
          <CloseButton onClick={() => removeMessage(id)} />
        </MessageWrap>
      ))}
    </Wrapper>
  )
}

export default inject('global')(observer(FlashMesssages))
