import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { inject } from 'mobx-react'
import styled from 'styled-components'
import { rem, isAny, value, flex, position, transparentize } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import Button from 'components/button'
import CloseButton from 'components/close-button'
import Price from 'components/price'

const TOAST_DURATION = 6000
let TOAST_TIMEOUT

const { colors, speeds } = theme
const { pearl, iron, ember, black } = colors

const Toast = styled.div`
  ${position('fixed', 0, 0, 'auto')}
  ${flex('column', 'flex-end', 'flex-start')}
  background: linear-gradient(${iron} 0%, ${iron} 25%, ${ember} 100%);
  box-shadow: 0 ${rem(-12)} ${rem(24)} ${transparentize(black, 0.7)};
  flex-wrap: nowrap;
  opacity: 0;
  width: 100%;
  z-index: 6;

  ${isAny('animation', ['toastDown', 'fadeOut'])`
    animation: ${value('animation')} ${speeds.gradual}ms forwards ease;
  `}

  &, small {
    color: ${pearl};
  }

  ${media.small`
    left: auto;
    width: ${rem(400)};
  `}

  ${media.large`
    left: calc(50% + ${rem(96)});
    right: auto;
  `}
`

const Details = styled.div`
  ${flex('row', 'center', 'flex-start')}
  background: linear-gradient(to right, ${iron}, ${ember});
  padding: ${rem(64)} ${rem(16)} ${rem(16)};
  width: 100%;
`

const Image = styled.div`
  background: ${ember};
  height: ${rem(48)};
  margin: 0 ${rem(16)} 0 0;
  width: ${rem(48)};

  img {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
`

const Message = styled.span`
  flex-grow: 1;
`

const Actions = styled.menu`
  ${flex('row', 'center', 'space-between')}
  width: 100%;
`

const StyledCloseButton = styled(CloseButton)`
  &,
  :hover,
  :focus {
    background: transparent;
  }

  svg {
    fill: ${pearl};
  }
`

const ItemToast = ({ cart: cartStore, item }) => {
  const [currentToastKey, setCurrentToastKey] = useState('')
  const [animation, setAnimation] = useState('none')
  const { product, toastKey } = item
  const { name, images } = product
  const price = cartStore.getItemPrice(item)

  const showToast = () => {
    setAnimation('toastDown')
    setCurrentToastKey(toastKey)
    debounceHide()
  }

  const dismissToast = () => {
    clearTimeout(TOAST_TIMEOUT)
    setAnimation('fadeOut')
    TOAST_TIMEOUT = setTimeout(() => {
      setAnimation('none')
      cartStore.setRecentlyAddedItem(null)
    }, speeds.gradual)
  }

  const debounceHide = () => {
    clearTimeout(TOAST_TIMEOUT)
    TOAST_TIMEOUT = setTimeout(dismissToast, TOAST_DURATION)
  }

  useEffect(() => {
    if (toastKey !== currentToastKey && animation === 'none') showToast()
    return () => clearTimeout(TOAST_TIMEOUT)
  }, [toastKey, currentToastKey, showToast])

  return (
    <Toast animation={animation}>
      <Details>
        <Image>
          <img src={images[0]} alt="" />
        </Image>
        <Message>{name} Added to Cart</Message>
        <Price cents={price} />
      </Details>
      <Actions>
        <StyledCloseButton onClick={dismissToast} kind="primary" />
        <Link to="/cart">
          <Button is="span" kind="primary">
            Checkout
          </Button>
        </Link>
      </Actions>
    </Toast>
  )
}

export default inject('cart')(ItemToast)
