import React from 'react'
import styled from 'styled-components'
import { rem, flex, position } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import Wrap from 'components/wrap'

const { colors, fonts, speeds } = theme
const { white, tar, silver, black, shadow } = colors

const StyledHeader = styled.header`
  ${position('fixed', 48, 0, 'auto')}
  background: ${white};
  box-shadow: 0 0 ${rem(32)} ${shadow};
  z-index: 5;

  > div {
    ${flex('row', 'flex-start', 'flex-start')}
    height: ${rem(48)};
    padding: 0;

    ${media.large`
      padding: 0 ${rem(16)};
    `}
  }

  a {
    color: ${tar};
    cursor: pointer;
    font-family: ${fonts.primary};
    font-size: ${rem(12)};
    font-weight: 200;
    letter-spacing: ${rem(2)};
    line-height: ${rem(48)};
    margin: 0 0 0 ${rem(2)};
    overflow: hidden;
    padding: 0 ${rem(16)};
    position: relative;
    text-transform: uppercase;
    transition: opacity ${speeds.quick}ms linear;

    :after {
      background: ${tar};
      border-radius: ${rem(1)};
      bottom: ${rem(2)};
      content: '';
      height: ${rem(2)};
      left: 50%;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      transform: translateX(-50%);
      transition:
        all ${speeds.quick}ms ease-in-out,
        opacity ${speeds.fast}ms ease-in-out;
      width: 0;
    }

    &.selected:after {
      background: ${silver} !important;
      transition: none;
    }

    &.selected,
    :hover {
      color: ${black};

      :after {
        background: ${tar};
        width: 100%;
        opacity: 1;
      }
    }
  }
`

const SubHeader = ({ children }) => (
  <StyledHeader>
    <Wrap>{children}</Wrap>
  </StyledHeader>
)

export default SubHeader
