import React from 'react'
import styled from 'styled-components'
import { rem, flex, is } from 'styled-tidy'
import theme from 'lib/styles/theme'
import Badge from 'components/badge'

const { speeds } = theme

const Count = styled(Badge)`
  left: 60%;
  position: absolute;
  top: -25%;

  ${is('animation', 'in')`
    animation: badgeIn ${speeds.quick}ms forwards;
    opacity: 0;
  `}
`

const Wrapper = styled.div.attrs(({ size }) => ({
  style: { width: rem(size), height: rem(size) },
}))`
  ${flex('row', 'center', 'center')}
  position: relative;
`

const Cart = ({ count, size, fill, ...rest }) => (
  <Wrapper size={size}>
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 1000 1000"
    >
      <path
        fill={fill || '#000'}
        d="M448 598l390-40c13-1 23-11 25-24l35-271a28 28 0 0 0-27-32l-556-18-39-92c-4-10-12-16-23-17L119 90a28 28 0 1 0-6 56l118 12 171 408-25 28c-12 14-48 56-31 94 16 36 65 37 70 37h430a28 28 0 0 0 0-56H416c-6 0-15-2-18-4 0-4 4-14 21-34l29-33zM338 269l501 17-29 219-358 37-114-273zM787 743a84 84 0 1 0 0 167 84 84 0 0 0 0-167zm0 111a28 28 0 1 1 0-56 28 28 0 0 1 0 56zM427 743a84 84 0 1 0 0 167 84 84 0 0 0 0-167zm0 111a28 28 0 1 1 0-56 28 28 0 0 1 0 56z"
      />
    </svg>
    {!!count && <Count>{count}</Count>}
  </Wrapper>
)

export default Cart
