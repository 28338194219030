import styled from 'styled-components'
import { rem, is } from 'styled-tidy'
import theme from 'lib/styles/theme'

const { colors, speeds } = theme
const { white, silver } = colors

const HamburgerToggle = styled.a`
  cursor: pointer;
  height: ${rem(48)};
  width: ${rem(48)};

  :before,
  :after {
    background: ${silver};
    content: '';
    height: ${rem(2)};
    left: ${rem(12)};
    position: absolute;
    transition: all ${speeds.quick}ms ease-in-out;
    transition-delay: 150ms;
    width: ${rem(24)};
  }
  :before {
    top: 50%;
    transform: translateY(${rem(-5)});
  }
  :after {
    top: 50%;
    transform: translateY(${rem(1)});
  }

  :hover {
    :before,
    :after {
      background: ${white};
    }
  }

  ${is('shape', 'closed')`
    :before {
      transform: translateY(${rem(-1)}) rotate(135deg);
    }
    :after {
      transform: translateY(${rem(-1)}) rotate(-135deg);
    }
  `}
`

export default HamburgerToggle
