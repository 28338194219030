import { createGlobalStyle } from 'styled-components'
import { rem, position } from 'styled-tidy'
import theme from 'lib/styles/theme'
import keyframes from 'lib/styles/keyframes'

const { colors, fonts } = theme
const { vapor, silver, tar } = colors

export default createGlobalStyle`
  * {
    border: 0;
    border-radius: 0;
    box-sizing: border-box;
    font-family: ${fonts.secondary};
    margin: 0;
    outline: none;
    padding: 0;
    text-decoration: none;
  }
  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  body {
    background: ${tar};
    font-weight: 400;
    margin: 0;

    :before {
      ${position('fixed', 0, 0, 'auto')}
      background: ${vapor};
      height: 50vh;
      content: '';
    }
  }
  a, a *, button, button * {
    cursor: pointer;
  }
  ::placeholder {
    color: ${silver};
    opacity: 1 !important;
  }

  ${keyframes}
`
