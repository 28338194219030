import React from 'react'
import styled from 'styled-components'
import { rem, flex, is, isnt } from 'styled-tidy'
import { Link } from 'gatsby'
import Sanitized from 'components/sanitized'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'

const { colors, fonts } = theme
const { black, shadow } = colors

const Title = ({
  is: tag = 'div',
  color = black,
  to,
  size,
  weight,
  align,
  children,
  ...rest
}) => {
  const Element = styled(tag)`
    ${flex('row', 'center', 'center')}
    border-bottom: ${rem(1)} solid ${shadow};
    color: ${color};
    font-family: ${fonts.secondary};
    font-size: ${size || '100%'};
    font-weight: ${weight || 200};
    letter-spacing: 0.1em;
    line-height: 1;
    margin: ${rem(16)} auto;
    padding: ${rem(8)} 10%;
    text-align: center;
    text-transform: uppercase;
    width: 100%;

    ${isnt('align')`
      ${media.small`
        justify-content: flex-start;
        padding: ${rem(8)} 0;
        text-align: ${align || 'left'};
      `}
    `}
    ${is('align', 'left')`
      text-align: left;
      justify-content: flex-start;
    `}
    ${is('align', 'right')`
      text-align: right;
      justify-content: flex-end;
    `}
  `

  return to ? (
    <Link to={to}>
      <Element {...rest}>
        {typeof children === 'string' ? (
          <Sanitized html={children} />
        ) : (
          children
        )}
      </Element>
    </Link>
  ) : (
    <Element {...rest}>
      {typeof children === 'string' ? <Sanitized html={children} /> : children}
    </Element>
  )
}

export default Title
