import React from 'react'
import styled from 'styled-components'
import { rem, flex } from 'styled-tidy'
import { Link } from 'gatsby'
import theme from 'lib/styles/theme'
import NetlifyBadge from 'components/icons/netlify-badge'
import Evilneon from 'components/icons/evilneon'
import Wrap from 'components/wrap'
import SocialLinks from 'components/social-links'
import ExternalLink from 'components/external-link'

const { colors, fonts, speeds } = theme
const { white, gray, ember, tar, yellow } = colors
const gradient = `to right, ${tar}, ${ember} 20%, 80%, ${tar} 100%`

const StyledFooter = styled.footer`
  background: ${tar};
  padding: ${rem(16)} ${rem(16)} ${rem(64)};
  position: relative;
  z-index: 2;

  hr {
    background: linear-gradient(${gradient});
    border: 0;
    height: ${rem(1)};
    margin: ${rem(16)};
    width: 100%;
  }
`

const StyledNav = styled.nav`
  ${flex('row', 'center', 'center')}
  width: 100%;

  a {
    ${flex('row', 'center', 'center')}
    color: ${gray};
    font-family: ${fonts.primary};
    font-weight: 200;
    letter-spacing: ${rem(2)};
    padding: ${rem(4)} ${rem(12)};
    text-transform: uppercase;
    transition: color ${speeds.quick}ms ease-in-out;

    svg {
      margin-right: ${rem(4)};
      transition: fill ${speeds.quick}ms ease-in-out;
    }

    :hover {
      color: ${white};

      svg {
        fill: ${white};
      }
    }
  }
`

const FooterLogoWrap = styled.div`
  margin: ${rem(40)} auto;

  svg {
    transition: fill ${speeds.quick}ms ease;
  }

  a {
    :hover,
    :focus {
      svg {
        animation: colorCycle ${speeds.slow}ms forwards infinite linear;
        animation-delay: ${speeds.gradual}ms;
        fill: ${yellow};
      }
    }
  }
`

const NetlifyBadgeWrap = styled.div`
  a {
    svg {
      transition: fill ${speeds.quick}ms ease;
    }

    :hover svg {
      fill: ${white};
    }
  }
`

const Footer = () => (
  <StyledFooter>
    <Wrap>
      <StyledNav flexDirection="column">
        <Link to="/shop">Shop</Link>
        <Link to="/commissions">Commissions</Link>
        <Link to="/gallery">Gallery</Link>
        <Link to="/about">About</Link>
      </StyledNav>
      <hr />
      <StyledNav>
        <SocialLinks />
      </StyledNav>
      <hr />
      <StyledNav>
        <Link to="/policies/returns">Terms & Policies</Link>
      </StyledNav>
      <FooterLogoWrap>
        <Link to="/">
          <Evilneon size={80} fill={gray} />
        </Link>
      </FooterLogoWrap>
      <hr />
      <NetlifyBadgeWrap aria-hidden>
        <ExternalLink href="https://www.netlify.com">
          <NetlifyBadge />
        </ExternalLink>
      </NetlifyBadgeWrap>
    </Wrap>
  </StyledFooter>
)

export default Footer
