import React from 'react'
import theme from 'lib/styles/theme'

const Facebook = ({ fill = theme.colors.black, size = 24, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    fill={fill}
    {...rest}
  >
    <path d="M22.7 0H1.3C.6 0 0 .6 0 1.3v21.4c0 .7.6 1.3 1.3 1.3h11.5v-9.3H9.7v-3.6h3.1V8.4c0-3 2-4.8 4.7-4.8l2.8.2V7h-2c-1.4 0-1.7.7-1.7 1.8V11H20l-.4 3.6h-3.1V24h6c.8 0 1.4-.6 1.4-1.3V1.3c0-.7-.6-1.3-1.3-1.3z" />
  </svg>
)

export default Facebook
