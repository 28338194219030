export default {
  fontSrc:
    'https://fonts.googleapis.com/css?family=Oswald:200,400,600|Montserrat:400,600&display=swap',
  shippingFrom: {
    name: 'Evilneon',
    phone: '509-987-7944',
    email: 'love@evilneon.com',
    street1: '1330 Bristol St',
    city: 'Costa Mesa',
    state: 'CA',
    zip: '92626',
    country: 'US',
  },
  social: {
    instagram: {
      url: 'https://www.instagram.com/evil.neon/',
    },
    facebook: {
      url: 'https://www.facebook.com/Evilneon1/',
    },
  },
  privacyContactContactEmail: 'love@evilneon.com',
  launchDate: new Date('November 11, 2019 11:11:00'),
  launchTitle: 'Store Launch<br /> 11/11 @ 11:11',
}
