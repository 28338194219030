import React, { useRef, useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import styled from 'styled-components'
import { rem, is } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'

const { speeds } = theme

const Wrapper = styled.div`
  overflow: hidden;

  main,
  footer {
    transition: transform ${speeds.quick}ms ease-in-out;
  }

  main {
    margin-top: ${rem(-1)};
  }

  ${media.small`
    main,
    footer {
      transform: translateX(0);
    }
  `}

  ${is('menuOpen')`
    main,
    footer {
      transform: translateX(${rem(-160)});
    }
  `}
`

export const SiteWrapper = ({ global: globalStore, children }) => {
  const { isMenuOpen, isBusy } = globalStore
  const wrapperRef = useRef(null)

  useEffect(() => {
    if (isMenuOpen || isBusy) disableBodyScroll(wrapperRef.current)
    else enableBodyScroll(wrapperRef.current)
    return () => clearAllBodyScrollLocks()
  })

  return (
    <Wrapper ref={wrapperRef} menuOpen={isMenuOpen}>
      {children}
    </Wrapper>
  )
}

export default inject('global')(observer(SiteWrapper))
