import styled from 'styled-components'
import { rem, flex } from 'styled-tidy'
import theme from 'lib/styles/theme'

const { yellow, ember } = theme.colors

const Badge = styled.mark`
  ${flex('row', 'center', 'center')}
  background: ${yellow};
  border-radius: ${rem(16)};
  color: ${ember};
  font-size: ${rem(11)};
  font-weight: 600;
  letter-spacing: 0;
  line-height: ${rem(15)};
  min-width: ${rem(15)};
  padding: 0 ${rem(4)};
`

export default Badge
