import React from 'react'
import styled from 'styled-components'
import { rem, choose } from 'styled-tidy'
import theme from 'lib/styles/theme'

const { iron, black } = theme.colors

const StyledParagraph = styled.p`
  align-items: center;
  color: ${iron};
  display: flex;
  font-size: ${rem(14)};
  font-weight: 200;
  justify-content: ${choose('align', {
    left: 'flex-start',
    right: 'flex-end',
    center: 'center',
  })};
  line-height: 1.2;
  margin: 0;
  padding: ${rem(8)} 0;
  text-align: ${({ align }) => align || 'left'};
  width: 100%;

  a {
    color: ${black};
    margin: 0 ${rem(4)};
    text-decoration: underline;
  }
`

const Paragraph = ({ children, ...rest }) => (
  <StyledParagraph {...rest}>{children}</StyledParagraph>
)

export default Paragraph
