import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { flex } from 'styled-tidy'
import { formatPrice } from 'lib/common/helpers'

const StyledPrice = styled.span`
  ${flex('row', 'flex-start', 'flex-end')}
  line-height: 1;

  small {
    font-size: 50%;
    letter-spacing: 0;
    padding: 0;
    vertical-align: top;
    text-decoration: underline;
  }
`

const Price = ({ cents }) => {
  const formattedPrice = formatPrice(cents)
  const [dollars, pennies] = formattedPrice.split(/\./)

  return (
    <StyledPrice>
      {dollars}.<small>{pennies}</small>
    </StyledPrice>
  )
}
Price.propTypes = {
  cents: PropTypes.number,
}

export default Price
