import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import styled from 'styled-components'
import {
  rem,
  flex,
  position,
  transparentize,
  is,
  swap,
  value,
} from 'styled-tidy'
import { Link } from 'gatsby'
import debounce from 'lodash.debounce'
import theme from 'lib/styles/theme'
import media, { sizes } from 'lib/styles/media'
import LogoText from 'components/logo-text'
import Cart from 'components/icons/cart'
import HamburgerToggle from 'components/icons/hamburger-toggle'
import Badge from 'components/badge'
import ItemToast from 'components/item-toast'
import Wrap from 'components/wrap'
import SocialLinks, { SocialLinksWrap } from 'components/social-links'
import SubHeader from 'components/sub-header'

const { colors, fonts, speeds } = theme
const { pearl, tar, white, black, pink, blue, orange, yellow } = colors

const Wrapper = styled.div`
  ${flex('column', 'flex-start', 'center')}
  min-height: ${value('minHeight')};
  padding-bottom: ${rem(1)};
  user-select: none;
`

const StyledLink = styled(Link)`
  color: ${pearl};
  cursor: pointer;
  font-family: ${fonts.primary};
  font-size: ${rem(12)};
  font-weight: 200;
  letter-spacing: ${rem(2)};
  line-height: ${rem(48)};
  overflow: hidden;
  padding: 0 ${rem(16)};
  position: relative;
  text-transform: uppercase;
  transition: opacity ${speeds.quick}ms linear;

  :after {
    background: ${({ line }) => line || white};
    border-radius: ${rem(1)};
    bottom: ${rem(2)};
    content: '';
    height: ${rem(2)};
    left: 50%;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    transform: translateX(-50%);
    transition: all ${speeds.quick}ms ease-in-out,
      opacity ${speeds.fast}ms ease-in-out;
    width: 0;
  }

  &.selected:after {
    transition: none;
  }

  &.selected,
  :hover {
    border-color: ${white};
    color: ${white};

    :after {
      width: 100%;
      opacity: 1;
    }
  }
`

const StyledBadge = styled(Badge)`
  margin: 0 ${rem(4)} ${rem(1)};
`

const BrandingLinkWrap = styled.div`
  ${flex('row', 'flex-start', 'flex-start')}
  flex: 1;

  a {
    font-family: ${fonts.secondary};
    font-size: ${rem(16)};
    font-weight: 400;
  }
`

const CartLinkWrap = styled.div`
  @keyframes highlight {
    0% { fill: ${pearl} }
    100% { fill: ${yellow} }
  }
  @keyframes unhighlight {
    0% { fill: ${yellow} }
    100% { fill: ${pearl} }
  }

  a {
    ${flex('row', 'center', 'center')}
    height: ${rem(48)};
    margin: 0 ${rem(48)} 0 ${rem(8)};
    padding: 0 ${rem(16)} 0 ${rem(4)};

    path {
      animation:
        ${swap('highlight', 'highlight', 'unhighlight')}
        ${speeds.gradual}ms forwards ease;
    }

    :hover path {
      animation: highlight ${speeds.quick}ms forwards ease;
      animation-play-state: running;
    }

    ${media.small`
      margin-right: 0;
    `}
  }
`

const StyledHeader = styled.header`
  ${position('fixed', 0, 0, 'auto')}
  background: ${swap('isMenuOpen', black, tar)};
  z-index: 7;
`

const StyledNav = styled(Wrap).attrs({
  is: 'nav',
  flexDirection: 'row',
  justifyContent: 'space-between',
})`
  &&& {
    height: ${rem(48)};
    padding: ${rem(8)} 0;
    position: relative;
    z-index: 2;
  }

  > a {
    display: none;
  }

  ${media.small`
    > a {
      display: block;
    }
  `}
`

const StyledMenu = styled.div`
  ${flex('row', 'flex-start', 'flex-start')}
  background: ${black};
  background: linear-gradient(90deg, ${black}, ${tar});
  ${position('fixed', 0, 0, 0, 'auto')}
  transform: translateX(${({ offsetx }) => rem(offsetx)});
  transition: all ${speeds.quick}ms ease-in-out;
  width: ${rem(160)};
  z-index: 8;
`

const StyledMenuMask = styled.div`
  ${position('fixed', 0, 0, '200%')}
  background: ${transparentize(black, 0.2)};
  opacity: 0;
  transition: opacity ${speeds.gradual}ms ease-in-out;
  z-index: 6;

  ${media.small`
    bottom: 100%;
    opacity: 0;
  `}

  ${is('menuOpen')`
    bottom: 0;
    opacity: 1;
  `}
`

const StyledMenuNav = styled.nav`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  ${SocialLinksWrap} {
    flex-direction: column;
  }

  a {
    ${flex('row', 'center', 'flex-start')}
    color: ${pearl};
    font-size: ${rem(12)};
    font-family: ${fonts.primary};
    font-weight: 200;
    letter-spacing: 0.2em;
    line-height: ${rem(16)};
    padding: ${rem(16)};
    text-transform: uppercase;
    transition: all ${speeds.quick}ms linear;
    width: 100%;

    ~ a {
      border-top: ${rem(1)} solid ${tar};
    }

    svg {
      margin: 0 ${rem(16)} 0 0;
      width: ${rem(24)};
    }

    path {
      fill: ${pearl};
      transition: all ${speeds.quick}ms linear;
    }

    :hover {
      background: ${tar};
      color: ${white};

      path {
        fill: ${white};
      }
    }
  }
`

const StyledHamburgerToggle = styled(HamburgerToggle)`
  ${position('absolute', 0, '100%', 0, 'auto')}

  ${media.small`
    display: none;
  `}
`

const Header = ({
  global: globalStore,
  cart: cartStore,
  path = '',
  subheader,
}) => {
  const { isMenuOpen } = globalStore
  const { itemsCount, recentlyAddedItem } = cartStore
  const toggleMenu = () => globalStore.toggleMenu()
  const closeMenu = () => globalStore.closeMenu()

  const handleWindowResize = debounce(() => {
    if (isMenuOpen && global.innerWidth >= sizes.small) closeMenu()
  }, 100)

  useEffect(() => {
    global.addEventListener('resize', handleWindowResize)
    return () => global.removeEventListener('resize', handleWindowResize)
  }, [handleWindowResize])

  return (
    <Wrapper minHeight={rem(subheader ? 96 : 48)}>
      {recentlyAddedItem && (
        <ItemToast key={recentlyAddedItem.toastKey} item={recentlyAddedItem} />
      )}
      <StyledHeader isMenuOpen={isMenuOpen}>
        <StyledNav>
          <BrandingLinkWrap>
            <Link to="/">
              <LogoText />
            </Link>
          </BrandingLinkWrap>
          <StyledLink
            line={pink}
            to="/shop"
            className={path.match(/\/shop.*/i) ? 'selected' : ''}
          >
            Shop
          </StyledLink>
          <StyledLink
            line={blue}
            to="/commissions"
            className={path.match(/\/commissions.*/i) ? 'selected' : ''}
          >
            Commissions
          </StyledLink>
          <StyledLink
            line={orange}
            to="/gallery"
            className={path.match(/\/gallery.*/i) ? 'selected' : ''}
          >
            Gallery
          </StyledLink>
          <CartLinkWrap highlight={!!recentlyAddedItem}>
            <Link to="/cart">
              <Cart size={24} count={itemsCount} />
            </Link>
          </CartLinkWrap>
        </StyledNav>
      </StyledHeader>
      <StyledMenu offsetx={isMenuOpen ? 0 : 160}>
        <StyledHamburgerToggle
          onClick={toggleMenu}
          shape={isMenuOpen ? 'closed' : 'open'}
        />
        <StyledMenuNav>
          <Link onClick={closeMenu} to="/">
            Home
          </Link>
          <Link onClick={closeMenu} to="/shop">
            Shop
          </Link>
          <Link onClick={closeMenu} to="/commissions">
            Commissions
          </Link>
          <Link onClick={closeMenu} to="/gallery">
            Gallery
          </Link>
          {!!itemsCount && (
            <Link onClick={closeMenu} to="/cart">
              Cart
              <StyledBadge>{itemsCount}</StyledBadge>
            </Link>
          )}
          <SocialLinks />
          <Link onClick={closeMenu} to="/policies/returns">
            Terms & Policies
          </Link>
        </StyledMenuNav>
      </StyledMenu>
      <StyledMenuMask onClick={closeMenu} menuOpen={isMenuOpen} />
      {subheader === 'policies' && (
        <SubHeader>
          <Link
            to="/policies/returns"
            className={path.match(/\/policies\/returns.*/i) ? 'selected' : ''}
          >
            Returns
          </Link>
          <Link
            to="/policies/privacy"
            className={path.match(/\/policies\/privacy.*/i) ? 'selected' : ''}
          >
            Privacy
          </Link>
        </SubHeader>
      )}
    </Wrapper>
  )
}

export default inject('global', 'cart')(observer(Header))
