import React from 'react'
import insane from 'insane'
import defaultAllowedTags from 'lib/allowed-tags'

export default ({
  html = '',
  is = 'div',
  allowedTags = defaultAllowedTags,
  allowedAttributes = {},
}) => {
  const sanitizedHtml = insane(html, { allowedTags, allowedAttributes })

  return React.createElement(is, {
    dangerouslySetInnerHTML: { __html: sanitizedHtml },
  })
}
