export const getParamFromQueryString = (param, queryString) => {
  if (typeof queryString !== 'string') return ''
  const regex = new RegExp(`.*${param}=([^&]+).*`, 'g')
  return queryString.match(regex) ? queryString.replace(regex, '$1') : ''
}

export const formatPrice = (cents, withCents = true, unit = '$') => {
  let price = (parseInt(cents, 10) / 100.0).toFixed(2)
  if (!withCents) price = parseInt(price, 10)
  const priceStr = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const prefix = cents < 0 ? '-' : ''

  return `${prefix}${unit}${priceStr}`
}
